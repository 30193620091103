import axios from 'axios';
import React from 'react';
import { API_ROUTE } from '../constants';
import Calendar from 'react-calendar';
import LoadingIcon from './LoadingIcon';
import 'react-calendar/dist/Calendar.css';
import { makeStyles } from '@material-ui/core';
import parse from 'date-fns/parse'
import { format, isBefore, isSameDay, sub, add } from 'date-fns';

const useStyles = makeStyles(() => ({
    calendar: {
        margin: "32px 0px",
        border: 'none',
        boxShadow: "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)",
        "& .react-calendar__tile": {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            "&:hover": {
                background: 'none'
            },
            "&:disabled": {
                color: 'rgb(133,133,133)',
                "&:hover": {
                    backgroundColor: "#f0f0f0"
                },
                cursor: 'not-allowed !important'
            },
            cursor: 'default !important'
        },
        "& .react-calendar__tile--active": {
            background: 'none !important',
            color: 'rgb(133, 133, 133)'
        },
        "& .react-calendar__tile--hasActive": {
            background: 'none'
        },
        "& .react-calendar__tile--now": {
            background: 'none',
            boxShadow: "inset 0px 0px 0px 4px green",
            "&:disabled": {
                backgroundColor: '#f0f0f0',
                color: 'rgb(133,133,133)',
                "&:hover": {
                    backgroundColor: "#f0f0f0 !important"
                },
                cursor: 'not-allowed'
            },
            "&:hover": {
                background: 'none !important',
            }
        },
        "& .react-calendar__month-view__days__day--neighboringMonth": {
            visibility: 'hidden'
          },
        "& .react-calendar__navigation__next2-button": {
            display: 'none'
        },
        "& .react-calendar__navigation__prev2-button": {
            display: 'none'
        },
        "& .react-calendar__navigation button:disabled": {
            background: 'none',
            cursor: 'default'
        },
        "& .react-calendar__month-view__days__day--weekend": {
            color: 'rgb(133, 133, 133)'
        },
        "& .react-calendar__year-view .react-calendar__tile": {
            cursor: 'pointer !important',
            "&:hover": {
                backgroundColor: '#f0f0f0 !important'
            }
        },
        "& abbr": {
            textDecoration: 'none'
        }
    }
}))

function PropertyCalendar({listingID}) {

    const classes = useStyles()

    const [calendarData, setCalendarData] = React.useState([])
    const [disabledDates, setDisabledDates] = React.useState([])
    const [isCalendarDataLoading, setIsCalendarDataLoading] = React.useState(true)
    const [startDate, setStartDate] = React.useState(new Date())

    const getCalendarData = async () => {
        const body = {
            listingID, 
            startDate: format(new Date(), 'yyyy-MM-dd'),
            endDate: format(add(new Date(), {years: 2}), 'yyyy-MM-dd')
        }
        const {data} = await axios.post(`${API_ROUTE}/fetchHostawayCalendar`, body)
        const cleanData = data.result.map((data) => {
            const parsedDate = parse(`${data.date} -1100`, "yyyy-MM-dd xxxx", new Date())

            return {
                date: parsedDate,
                isAvailable: data.isAvailable,
                status: data.status,
                }
        })
        setCalendarData(cleanData)
        setIsCalendarDataLoading(false)
    }

    React.useEffect(() => {
        getCalendarData()
        // eslint-disable-next-line
    }, [])

    React.useEffect(() => {
        setDisabledDates(calendarData.reduce((acc, curr) => {
            if (!curr.isAvailable) {
                return [...acc, curr.date]
            } else {
                return acc
            }
        }, []))
    }, [calendarData])


    const tileDisabled = ({date, view}) => {
        if (view !== 'month') {
            return false;
        }
        if (isBefore(date, sub(new Date(), {days: 1}))) {
            return true;
        }
        return disabledDates.find(dDate => isSameDay(dDate, date))
    }

    const onViewChange = ({activeStartDate}) => {
        setStartDate(activeStartDate)
    }

    return (
        <>
            {isCalendarDataLoading ? <LoadingIcon/> : (
                <Calendar 
                    activeStartDate={startDate}
                    onActiveStartDateChange={onViewChange}
                    calendarType='gregory'
                    minDetail="year"
                    className={classes.calendar}
                    tileDisabled={tileDisabled}
                />
            )}
        </>
    )
}

export default PropertyCalendar