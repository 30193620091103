import React from 'react';
import PageLayout from '../components/PageLayout'
import { Grid, makeStyles } from '@material-ui/core';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import SlideInContainer from '../components/SlideInContainer';
import ImageScroller from '../components/ImageScroller';
import { CabinScroller, BothPropertiesScroller, SiloScroller } from '../images/ScrollerLists';
import PawPrintLogo from '../images/logos/paw print.png';
import OwlsNestLogo from '../images/logos/Owls Nest.png';
import FurNFeathersLogo from '../images/logos/FurNFeathers-tree-black-192.png'
import { CabinMoonlight, FlintHillsSunrise } from '../images/Pictures';
import Picture from '../components/Picture';
import { BOOKING_SUBDOMAIN, BOTH_LISTING_NUMBER, CABIN_LISTING_NUMBER, PAGE_TAGLINES, PROPERTY_DESCRIPTIONS, SILO_LISTING_NUMBER } from '../constants';
import PropertyCalendar from '../components/PropertyCalendar';


const useStyles = makeStyles(() => ({
    sectionContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        margin: 0,
        paddingTop: '64px',
    },
    paddedContainer: {
        padding: '0 15vw',
        overflowX: 'hidden',
        "@media screen and (max-width: 600px)": {
            padding: '0 5vw'
        }
    },
    propertyList: {
        "& #spacer": {
            height: '128px',
            "@media screen and (max-width: 600px)": {
                height: '64px',
            }
        },
        "& .MuiGrid-item": {
            padding: '16px',
        },
        "& .MuiGrid-container": {
            "& a:hover": {
                fontSize: '19px',
                margin: '-2px 0px'
            }
        },
        "@media screen and (max-width: 600px)": {
            "& .MuiGrid-container": {
                "& h2": {
                    marginTop: 0
                },
            }
        }
    },
    lowerImage: {
        position: 'relative',
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        '& a': {
            position: 'absolute',
            zIndex: 2
        },
        '& button': {
            backgroundColor: "#F5F5F5", 
            fontSize: '20px', 
            lineHeight: 0, 
            padding: '30px 40px', 
            opacity: 0.7,
            "&:hover": {
                opacity: 1
            }
        },
        '& picture': {
            width: '100%'
        },
        '& img': {
            height: '80vh',
            width: '100%',
            objectFit: 'cover',
            "@media screen and (max-width: 600px)": {
                height: '40vh'
            }
        }
    },
}))

function HomeSection({title, children}) {

    return (
        <Grid item xs={12} md={3}>
            <SlideInContainer direction="bottom" id={`slide-in-${title}`}>
                <h2 style={{marginBottom: 0, fontSize: '32px'}}>{title}</h2>
                <span id="underline"/>
                <p>{children}</p>
            </SlideInContainer>
        </Grid>
    )
}

function PropertyTile({flippedOrientation = false, imageList, propertyName, viewName, accessory, description, listingNumber}) {
    return (
        <SlideInContainer direction={flippedOrientation ? 'left' : 'right'} id={`slide-in-${propertyName}`}>
            <Grid container direction={flippedOrientation ? 'row-reverse' : 'row'}>
                <Grid item xs={12} md={7} lg={6} style={{display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'space-around'}}>
                    <h2 style={{display: 'inline-flex', alignItems: 'center', fontSize: '32px'}}>
                        {!!accessory && <img src={accessory} height="32px" alt={`${propertyName} logo`} style={{display: 'inline', padding: '0 4px'}}/>}
                        {` | ${propertyName}`}
                    </h2>
                    <ImageScroller imageList={imageList} width="100%"/>
                </Grid>
                <Grid item xs={12} md={5} lg={6} style={{textAlign: (flippedOrientation ? 'end' : 'start'), alignSelf: 'center'}}>
                    <p>{description}</p>
                    <div style={{display: 'flex', width: '100%', justifyContent: (flippedOrientation ? 'end' : 'start')}}>
                        <PropertyCalendar listingID={listingNumber}/>
                    </div>
                    <a href={`${BOOKING_SUBDOMAIN}/listings/${listingNumber}`} target="_blank" rel="noreferrer" style={{textDecoration: 'none', color: 'black', display: 'flex', alignItems: 'center', justifyContent: (flippedOrientation ? 'end' : 'start')}}>
                        {flippedOrientation ? <FaAngleLeft/> : null} BOOK {viewName.toUpperCase()} {flippedOrientation ? null : <FaAngleRight/>}
                    </a>
                </Grid>
            </Grid>
        </SlideInContainer>
    )
}

function PropertiesList({className}) {

    const Spacer = () => (<div id="spacer"/>)

    return (
        <div className={className}>
            <Spacer/>
            <PropertyTile 
                imageList={CabinScroller} 
                propertyName="Paw Print Cabin"
                viewName="Cabin"
                accessory={PawPrintLogo}
                description={PROPERTY_DESCRIPTIONS.cabin}
                listingNumber={CABIN_LISTING_NUMBER}
            />
            <Spacer/>
            <PropertyTile 
                flippedOrientation 
                imageList={SiloScroller} 
                propertyName="Owls Nest Silo"
                viewName="Silo"
                accessory={OwlsNestLogo}
                description={PROPERTY_DESCRIPTIONS.silo}
                listingNumber={SILO_LISTING_NUMBER}
            />
            <Spacer/>
            <PropertyTile 
                imageList={BothPropertiesScroller} 
                propertyName="Both Properties"
                viewName="Both"
                accessory={FurNFeathersLogo}
                description={PROPERTY_DESCRIPTIONS.both}
                listingNumber={BOTH_LISTING_NUMBER}
            />
            <Spacer/>
        </div>
    )
}
  
function Home() {
    const classes = useStyles()

    return (
        <PageLayout headerImage={CabinMoonlight} overlayText={PAGE_TAGLINES.home}>
            <div className={classes.paddedContainer}>
                <Grid container className={classes.sectionContainer}>
                    <HomeSection title="unique">
                        Stay in original and creative spaces unlike any others in the area.
                    </HomeSection>
                    <HomeSection title="charming">
                        Enjoy the cozy and inviting atmosphere of the cabin and the silo.   
                    </HomeSection>
                    <HomeSection title="scenic">
                        Experience the natural beauty of the Flint Hills and Middle Creek. 
                    </HomeSection>
                </Grid>
                <PropertiesList className={classes.propertyList}/>
            </div>
            <div className={classes.lowerImage}>
                <Picture 
                    src={FlintHillsSunrise} 
                    imgProps={{loading: 'lazy'}}
                />
                <a href={BOOKING_SUBDOMAIN} target="_blank" rel="noreferrer"><button>BOOK NOW</button></a>
            </div>
        </PageLayout>
    )
};
  
export default Home;